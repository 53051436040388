import React, { useState } from 'react';
import Select  from "react-select";
import EnquiryForm from './EnquiryForm';
import { pdf } from '@react-pdf/renderer';

export default function EnquiryContent() {
    const [showGearedForm , setShowGearedForm] = useState(false);
    const [showMotorForm , setShowMotorForm] = useState(false);
    const [showVfdForm , setShowVfdForm] = useState(false);

    const [enquiryData , setEnquiryData] = useState({
      companyName:"",
      contactPerson:"",
      companyAddress:"",
      email:"",
      phone:"",
      application:"",
      projectNumber:"",
      gearboxQty:"",
      gearboxType:"",
      gearboxOutputShaft:"",
      gearboxMounting:"",
      gearboxMountingPosition:"",
      gearboxInputPower:"",
      gearboxOutputShaftDimension:"",
      outputRpm:"",
      outputRatio:"",
      outputTorque:"",
      serviceFactor:"",
      gearboxSpecialNote:"",
      motorQty:"",
      motorFrame:"",
      motorPower:"",
      motorRpm:"",
      motorCurrent:"",
      ipProtectionClass:"",
      ambientTemperature:"",
      motorVoltage:"",
      motorPole:"",
      motorBody:"",
      motorConnection:"",
      motorEfficiency:"",
      motorMounting:"",
      brakeMotor:"",
      forceCoolingFan:"",
      encoderOption:"",
      motorEncoderType:"",
      motorOperation:"",
      insulationClass:"",
      motorSpecialNote:"",
      vfdQty:"",
      motorPowerVoltageAmpere:"",
      vfdPower:"",
      vfdInputVoltage:"",
      numberOfDigitalInput:"",
      numberOfDigitalOutput:"",
      numberOfRelayOutput:'',
      numberOfAnalogInput:"",
      numberOfAnalogOutput:"",
      analogInputVoltage:"",
      analogOutputVoltage:"",
      typeOfCommunication:"",
      vfdToMotorDistance:"",
      encoderSupported:"",
      encoderType:"",
      inputChokeRequired:"",
      outputChokeRequired:"",
      motorConnected:"",
      environmentalTemperature:"",
      environmentalCondition:"",
      breakingRegister:"",
      altitude:"",
      vfdSpecialNote:""
    });

    const [validCompanyName , setValidCompanyName] = useState(null);
    const [validContactPerson , setValidContactPerson] = useState(null);
    const [validPhone , setValidPhone] = useState(null);
    const [validCompanyAddress , setValidCompanyAddress] = useState(null);
    const [validEmail , setValidEmail] = useState(null);

    const [showTypeOptionMotor , setShowTypeOptionMotor] = useState(false);
    const [showTypeOptionVfd , setShowTypeOptionVfd] = useState(false);

    const [buffering , setBuffering] = useState(false);

    const selectComponentStyles = {
      container: (provided) => ({
        ...provided,
        width: "100%",
        fontWeight: "400",
      }),
      control: (provided) => ({
        ...provided,
        border: "2px solid transparent",
        fontFamily: "Roboto, sans-serif",
        fontWeight: "400",
        border: "none",
        padding:"0px",
        boxShadow: "none",
        "&:hover": {
          border: "none",
        },
        fontSize:"0.8em",
        height: "15px"
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: 9999,
        marginLeft: "0px",
        padding:"0px",
        fontSize:"0.8em"
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#003b66" : "#ffffff",
        color: state.isSelected ? "#ffffff" : "#000000",
        fontWeight: "400",
        "&:hover": {
          backgroundColor: "#003b66",
          color: "#ffffff",
        },
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#000000",
        fontWeight: "400",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#6a6969",
        fontWeight: "400",
      }),
    };

    async function handleSubmitEnquiryForm()
    {
      setBuffering(true);

      const enquiryId = Math.ceil((Math.random()*900000)+100000);

      const todayTimestamp = Date.now(); 
      const todayDate = new Date(todayTimestamp); 

      const year = todayDate.getFullYear();
      const month = String(todayDate.getMonth() + 1).padStart(2, '0'); 
      const day = String(todayDate.getDate()).padStart(2, '0');

      const formattedDate = `${day}/${month}/${year}`;
      
      console.log(enquiryData);
      try{
        const blob = await pdf(
      <EnquiryForm 
      enquiryId = {enquiryId}
      date= {formattedDate}
      enquiryData = {enquiryData}
      />
    ).toBlob();
    const formData = new FormData();
    formData.append(`pdf`, blob, `Enquiry_ID_${enquiryId}.pdf`);
    // https://dvps.in.net/upload.php
    await fetch('https://dvps.in.net/upload.php', {
      method: 'POST',
      body: formData,
    })
    .then(async(response) => {
      response = await response.json();
      if(response.success)
      {//https://dvps.in.net/sendEmail.php
        await fetch("https://dvps.in.net/sendEmail.php" , {
          method:"post",
          body: JSON.stringify({fileName: `uploads/Enquiry_ID_${enquiryId}.pdf`})
        })
        .then(async(res)=>{
          res=await res.json();
          if(res.success)
          {
            alert("Your Enquiry submitted successfully!");
            setBuffering(false);
            window.location.reload();
          }
          else
          {
            alert(res.error);
            setBuffering(false);
            window.location.reload();
          }
        })
        .catch(err=> {
          alert("Please check your internet connectivity!");
          console.log(err);
          setBuffering(false);
          window.location.reload();
        })
      }
      else
      {
        alert(response.error);
        setBuffering(false);
        window.location.reload();
      }
    })
    .catch(error => {
      setBuffering(false);
      window.location.reload();
      alert("Internal server Error!");
    })
  }
    catch(error)
    {
      console.log(error);
      alert("Something went wrong!");
      setBuffering(false);
      // window.location.reload();
    }
    }
  return (
    <div className='pb-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <div className="py-4 mt-0 bg-theme-blue-transparent col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 row px-5 display-flex-align-items-top">
            {/* <div className="fs-large-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-3 display-flex-start">
                ENQUIRY FORM 
            </div> */}

            <div className="mt-0 fs-medium-css fw-normal color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-3 display-flex-start">
                Personal Information
            </div>
            <div className="mt-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex-column">
              <input
                type="text"
                className="mt-1 input-field-form-enquiry rounded w-100"
                placeholder="Company Name*"
                onChange={(event)=>{
                  // setEnquiryData({...enquiryData , companyName : event.target.value})
                  if(event.target.value!=="")
                  {
                    setValidCompanyName(/^[A-Za-z\s]+$/.test(event.target.value));
                  }
                  else
                  {
                    setValidCompanyName(null);
                  }

                  if(validCompanyName===true)
                  {
                    setEnquiryData({...enquiryData , companyName: event.target.value});
                  }
                }}
              />

              <div className='w-100 height-30px px-0 display-flex-start fs-small-css'>
                {validCompanyName===false && "Invalid Company Name Format"}
                {/* {validCompanyName===null && "*"} */}
              </div>
            </div>

            <div className="mt-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex-column">
              <input
                type="text"
                className="mt-1 input-field-form-enquiry rounded w-100"
                placeholder="Contact Person Name*"
                onChange={(event)=>{
                  // setEnquiryData({...enquiryData , companyName : event.target.value})
                  if(event.target.value!=="")
                  {
                    setValidContactPerson(/^[A-Za-z\s]+$/.test(event.target.value));
                  }
                  else
                  {
                    setValidContactPerson(null);
                  }

                  if(validContactPerson===true)
                  {
                    setEnquiryData({...enquiryData , contactPerson: event.target.value});
                  }
                }}
              />

                <div className='w-100 height-30px px-0 m-0 display-flex-start fs-small-css'>
                {validContactPerson===false && "Invalid Contact Person Name Format"}
                {/* {validContactPerson===null && "*"} */}
              </div>
            </div>

            <div className="mt-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex-column">
              <input
                type="phone"
                max={10}
                maxLength={10}
                className="mt-1 input-field-form-enquiry rounded w-100"
                placeholder="Phone*"
                onChange={(event)=>{
                  // setEnquiryData({...enquiryData , companyName : event.target.value})
                  if(event.target.value!=="")
                  {
                    setValidPhone(/^[0-9\s]+$/.test(event.target.value));
                  }
                  else
                  {
                    setValidPhone(null);
                  }

                  if(validPhone===true)
                  {
                    setEnquiryData({...enquiryData , phone: event.target.value});
                  }
                }}
              />

              <div className='w-100 height-30px px-0 m-0 display-flex-start fs-small-css'>
                {validPhone===false && "Invalid Phone Number Format"}
                {/* {validPhone===null && "*"} */}
              </div>
            </div>

            <div className="mt-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex-column">
              <input
                type="text"
                className="mt-1 input-field-form-enquiry rounded w-100"
                placeholder="Company Address*"
                onChange={(event)=>{
                  // setEnquiryData({...enquiryData , companyName : event.target.value})
                  if(event.target.value!=="")
                  {
                    setValidCompanyAddress(/^[A-Za-z0-9/\-.\s]+$/.test(event.target.value));
                  }
                  else
                  {
                    setValidCompanyAddress(null);
                  }

                  if(validCompanyAddress===true)
                  {
                    setEnquiryData({...enquiryData , companyAddress: event.target.value});
                  }
                }}
              />

              <div className='w-100 height-30px px-0 m-0 display-flex-start fs-small-css'>
                {validCompanyAddress===false && "Invalid Company Address Format"}
                {/* {validCompanyAddress===null && "*"} */}
              </div>  
            </div>

            <div className="mt-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex-column">
              <input
                type="text"
                className="mt-1 input-field-form-enquiry rounded w-100"
                placeholder="Email ID*"
                // maxLength={25}
                // max={25}
                onChange={(event)=>{
                  // setEnquiryData({...enquiryData , companyName : event.target.value})
                  if(event.target.value!=="")
                  {
                    setEnquiryData({...enquiryData , email: event.target.value})
                    setValidEmail(true);
                  //   if(
                  //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(event.target.value)===true || 
                  //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\.[a-zA-Z]{2,}$/.test(event.target.value)===true
                  // )
                  // {
                  //   setEnquiryData({...enquiryData , email: event.target.value})
                  //   setValidEmail(true);
                  // }
                  // else
                  // {
                  //   setValidEmail(false);
                  // }
                  }
                  else
                  {
                    setValidEmail(null);
                  }
                }}
              />
              <div className='w-100 height-30px px-0 m-0 display-flex-start fs-small-css'>
                {validEmail===false && "Invalid Email ID Format"}
                {/* {validEmail===null && "*
                compulsory"} */}
              </div> 
            </div>

            <div className="mt-0 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
              <input
                type="text"
                className="mt-1 input-field-form-enquiry rounded w-100"
                placeholder="Application"
                onChange={(event)=>setEnquiryData({...enquiryData , application : event.target.value})}
              />
            </div>

            {/* <div className="mt-0 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row px-3 display-flex">
              <input
                type="text"
                className="mt-1 input-field-form-enquiry rounded w-100"
                placeholder="Project No."
                onChange={(event)=>setEnquiryData({...enquiryData , projectNumber : event.target.value})}
              />
            </div> */}
            <div className='text-center w-100 display-flex-center px-0 mx-0 height-30px fs-small-css mt-0'>
                   Please note, try to fill maximum details in below forms and leave blank if not known. The QTY field is mandatory
                </div>



            <div 
            onClick={()=>{
              if(showGearedForm===false)
                  {
                    setShowGearedForm(true);
                  }
                  else
                  {
                      setShowGearedForm(false);
                  }
          }}
            className="mt-4 fs-medium-css fw-normal color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-3 display-flex-between border-theme-dark-blue">
                <div className="w-auto px-0">
                    <span className="w-auto px-0 m-0">Gearbox Info</span>
                    
                </div>

                <button
                onClick={()=>{
                    if(showGearedForm===false)
                        {
                          setShowGearedForm(true);
                        }
                        else
                        {
                            setShowGearedForm(false);
                        }
                }}
                className='btn-navbar-internal-dropdown w-auto fs-larger-css'>
                <i className="fa-solid fa-angle-down"></i>
                </button>

                
            </div>

            {showGearedForm===true && <>

              <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                QTY :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="QTY"
                onChange={(event)=>setEnquiryData({...enquiryData , gearboxQty : event.target.value})}
              />
            </div>


            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                    Gearbox Type :
                </div>
              <Select styles={selectComponentStyles} 
               
              options={[
                {value:"Worm", label:"Worm"},
                {value:"HeliWorm", label:"HeliWorm"},
                {value:"Helical Inline", label:"Helical Inline"},
                {value:"Helical Parallel", label:"Helical Parallel"},
                {value:"Helical Bavel", label:"Helical Bavel"},
                {value:"Planetary", label:"Planetary"}
              ]} 
              className='w-100 mt-2'
              placeholder={"Gearbox Type"}
              onChange={(selectedOption)=>setEnquiryData({...enquiryData , gearboxType : selectedOption.value})}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                    Gearbox Input :
                </div>
              <Select styles={selectComponentStyles} 
               
              options={[
                {value:"IEC B5 Flange Hollow I/P", label:"IEC B5 Flange Hollow I/P"},
                {value:"Solid Shaft I/P", label:"Solid Shaft I/P"}
              ]} 
              className='w-100 mt-2'
              placeholder={"Gearbox Input"}
              onChange={(selectedOption)=>setEnquiryData({...enquiryData , gearboxInput : selectedOption.value})}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                    Gearbox Output Shaft :
                </div>
              <Select styles={selectComponentStyles} 
               
              options={[
                {value:"Hollow Shaft", label:"Hollow Shaft"},
                {value:"Solid Shaft", label:"Solid Shaft"}
              ]} 
              className='w-100 mt-2'
              placeholder={"Gearbox Output Shaft"}
              onChange={(selectedOption)=>setEnquiryData({...enquiryData , gearboxOutputShaft : selectedOption.value})}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                    Gearbox Mounting :
                </div>
              <Select styles={selectComponentStyles} 
               
              options={[
                {value:"Flange", label:"Flange"},
                {value:"Foot", label:"Foot"},
                {value:"Shaft Mounting", label:"Shaft Mounting"}
              ]} 
              className='w-100 mt-2'
              placeholder={"Gearbox Mounting"}
              onChange={(selectedOption)=>setEnquiryData({...enquiryData , gearboxMounting : selectedOption.value})}
              />
            </div>

            


            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                GearBox Input Power (kW) :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="GearBox Input Power"
                onChange={(event)=>setEnquiryData({...enquiryData , gearboxInputPower: event.target.value})}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                GearBox Output Shaft Dimension :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="GearBox Output Shaft Dimension"
                onChange={(event)=>setEnquiryData({...enquiryData , gearboxOutputShaftDimension : event.target.value})}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                Output RPM  :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="Output RPM"
                onChange={(event)=>setEnquiryData({...enquiryData , outputRpm : event.target.value})}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                Ratio  :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="Ratio"
                onChange={(event)=>setEnquiryData({...enquiryData , outputRatio : event.target.value})}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                Output Torque :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="Output Torque"
                onChange={(event)=>setEnquiryData({...enquiryData , outputTorque : event.target.value})}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                Service Factor :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="Service Factor"
                onChange={(event)=>setEnquiryData({...enquiryData , serviceFactor : event.target.value})}
              />
            </div>
            
            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex-align-items-top">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                    Gearbox Mounting Position :
                </div>
              <Select styles={selectComponentStyles} 
               
              options={[
                {value:"1(B3)", label:"1(B3)"},
                {value:"2(B7)", label:"2(B7)"},
                {value:"3(B6)", label:"3(B6)"},
                {value:"4(B8)", label:"4(B8)"},
                {value:"5(V5)", label:"5(V5)"},
                {value:"6(V6)", label:"6(V6)"}
              ]} 
              className='w-50 mt-2'
              placeholder={"Select"}
              onChange={(selectedOption)=>setEnquiryData({...enquiryData , gearboxMountingPosition : selectedOption.value})}
              />
              <div className='w-50 mt-2 display-flex px-2 '>
                  <img src='https://dvps.in.net/dvps_images/dvps_diagram.PNG' alt='Motor Mounting Diagram' className='w-100' />
              </div>
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Special Note :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Special Note"
                    onChange={(event)=> setEnquiryData({...enquiryData , gearboxSpecialNote : event.target.value})}
                  />
                </div>  
            
            </>}
            

            <div onClick={()=>{
                    if(showMotorForm===false)
                        {
                          setShowMotorForm(true);
                        }
                        else
                        {
                            setShowMotorForm(false);
                        }
                }} className="mt-4 fs-medium-css fw-normal color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-3 display-flex-between border-theme-dark-blue border-theme-dark-blue">
                <div className="w-auto px-0 ">
                    <span className="w-auto px-0 m-0">Motor Info</span>
                    
                </div>

                <button
                onClick={()=>{
                    if(showMotorForm===false)
                        {
                          setShowMotorForm(true);
                        }
                        else
                        {
                            setShowMotorForm(false);
                        }
                }}
                className='btn-navbar-internal-dropdown w-auto fs-larger-css'>
                <i className="fa-solid fa-angle-down"></i>
                </button>

                
            </div>

            {showMotorForm===true && <div className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex `}>

            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                QTY :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="QTY"
                onChange={(event)=>setEnquiryData({...enquiryData , motorQty : event.target.value})}
              />
            </div>


                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Frame :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Motor Frame"
                onChange={(event)=>setEnquiryData({...enquiryData , motorFrame : event.target.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Power (kW / HP) :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Motor Power (kW / HP)"
                onChange={(event)=>setEnquiryData({...enquiryData , motorPower : event.target.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor RPM :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Motor RPM"
                onChange={(event)=>setEnquiryData({...enquiryData , motorRpm : event.target.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Current :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Motor Current"
                onChange={(event)=>setEnquiryData({...enquiryData , motorCurrent : event.target.value})}
                  />
                </div>

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        IP Protection Class :
                    </div>
                    {/* <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="IP Protection Class"
                onChange={(event)=>setEnquiryData({...enquiryData , ipProtectionClass : event.target.value})}
                  /> */}
                  <Select styles={selectComponentStyles}
                  options={[
                    {value:"IP 55", label:"IP 55"},
                    {value:"IP 66", label:"IP 66"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"IP Protection Class"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , ipProtectionClass : selectedOption.value})}
                  />
                </div>

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Ambient Temperature :
                    </div>
                    {/* <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Ambient Temperature"
                onChange={(event)=>setEnquiryData({...enquiryData , ambientTemperature : event.target.value})}
                  /> */}
                  <Select styles={selectComponentStyles}
                  options={[
                    {value:"Less than 50 degree", label:"Less than 50 degree"},
                    {value:"More than 50 degree", label:"More than 50 degree"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Ambient Temperature"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , ambientTemperature : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Voltage :
                    </div>
                    <Select styles={selectComponentStyles}
                  options={[
                    {value:"Single Phase 230V", label:"Single Phase 230V"},
                    {value:"Three Phase 230V", label:"Three Phase 230V"},
                    {value:"Three Phase 415V", label:"Three Phase 415V"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Voltage"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorVoltage : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Pole :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"2P", label:"2P"},
                    {value:"4P", label:"4P"},
                    {value:"6P", label:"6P"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Pole"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorPole : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Body :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"AL", label:"AL"},
                    {value:"CI", label:"CI"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Body"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorBody : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Duty :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"S1", label:"S1"},
                    {value:"S2", label:"S2"},
                    {value:"S3", label:"S3"},
                    {value:"S4", label:"S4"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Duty"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorDuty : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Connection :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"Star", label:"Star"},
                    {value:"Delta", label:"Delta"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Connection"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorConnection : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Frequency :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"50Hz", label:"50Hz"},
                    {value:"60Hz", label:"60Hz"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Frequency"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorFrequency : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Efficiency :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"IE2", label:"IE2"},
                    {value:"IE3", label:"IE3"},
                    {value:"IE4", label:"IE4"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Efficiency"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorEfficiency : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Mounting :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"B03 Foot", label:"B03 Foot"},
                    {value:"IEC B05 Flange", label:"IEC B05 Flange"},
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Mounting"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorMounting : selectedOption.value})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Brake Motor :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"YES", label:"YES"},
                    {value:"NO", label:"NO"},
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Brake Motor"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , brakeMotor: selectedOption.label})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Force Cooling FAN :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"YES", label:"YES"},
                    {value:"NO", label:"NO"},
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Force Cooling FAN"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , forceCoolingFan: selectedOption.label})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        ENCODER OPTION :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"YES", label:"YES"},
                    {value:"NO", label:"NO"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"ENCODER OPTION"}
                  onChange={(selectedOption)=>{
                    if(selectedOption.label==="YES")
                    {
                      setShowTypeOptionMotor(true);
                    }
                    else
                    {
                      setShowTypeOptionMotor(false);
                    }
                    setEnquiryData({...enquiryData , encoderOption: selectedOption.label})}}
                  />
                </div>

                {showTypeOptionMotor===true && 
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        ENCODER Type :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"TTL", label:"TTL"},
                    {value:"HTL", label:"HTL"},
                    {value:"Absolute", label:"Absolute"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"ENCODER Type"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorEncoderType: selectedOption.label})}
                  />
                </div>}
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Operation :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"DOL", label:"DOL"},
                    {value:"VFD", label:"VFD"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Motor Operation"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , motorOperation: selectedOption.label})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Insulation Class :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"F", label:"F"},
                    {value:"H", label:"H"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Insulation Class"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , insulationClass: selectedOption.label})}
                  />
                </div>
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Special Note :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Special Note"
                  onChange={(event)=>setEnquiryData({...enquiryData , motorSpecialNote: event.target.value})}
                  />
                </div>
            </div>}


            <div onClick={()=>{
                    if(showVfdForm===false)
                        {
                          setShowVfdForm(true);
                        }
                        else
                        {
                            setShowVfdForm(false);
                        }
                }} className="mt-4 fs-medium-css fw-normal color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-3 display-flex-between border-theme-dark-blue">
                <div className="w-auto px-0 ">
                    <span className="w-auto px-0 m-0">VFD Info</span>
                    
                </div>

                <button
                onClick={()=>{
                    if(showVfdForm===false)
                        {
                          setShowVfdForm(true);
                        }
                        else
                        {
                            setShowVfdForm(false);
                        }
                }}
                className='btn-navbar-internal-dropdown w-auto fs-larger-css'>
                <i className="fa-solid fa-angle-down"></i>
                </button>

                
            </div>

            {showVfdForm===true && 
            <>

            <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                QTY :
                </div>
                <input
                type="text"
                className="mt-2 input-field-form-enquiry rounded w-100"
                placeholder="QTY"
                onChange={(event)=>setEnquiryData({...enquiryData , vfdQty : event.target.value})}
              />
            </div>


            {/* <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Motor Power / Voltage / Ampere :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Motor Power"
                    onChange={(event)=> setEnquiryData({...enquiryData , motorPowerVoltageAmpere : event.target.value})}
                  />
                </div> */}

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        VFD Power in KW :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="VFD Power in KW"
                    onChange={(event)=> setEnquiryData({...enquiryData , vfdPower : event.target.value})}
                  />
                </div>

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        VFD I/P Voltage :
                    </div>
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"Single Phase 230V", label:"Single Phase 230V"},
                    {value:"Three Phase 230V", label:"Three Phase 230V"},
                    {value:"Three Phase 415V", label:"Three Phase 415V"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"VFD I/P Voltage"}
                  onChange={(selectedOption)=> setEnquiryData({...enquiryData , vfdInputVoltage : selectedOption.value})}
                  />
                </div>


                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Number Of Digital I/P:
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Number Of Digital I/P"
                    onChange={(event)=> setEnquiryData({...enquiryData , numberOfDigitalInput : event.target.value})}
                  />
                </div>

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Number Of Digital O/P:
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Number Of Digital O/P"
                    onChange={(event)=> setEnquiryData({...enquiryData , numberOfDigitalOutput : event.target.value})}
                  />
                </div>

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Number Of Relay O/P:
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Number Of Relay O/P"
                    onChange={(event)=> setEnquiryData({...enquiryData , numberOfRelayOutput : event.target.value})}
                  />
                </div>

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Number Of analog I/P:
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Number Of analog I/P"
                    onChange={(event)=> setEnquiryData({...enquiryData , numberOfAnalogInput : event.target.value})}
                  />
                </div>

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Number Of analog O/P:
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Number Of analog O/P"
                    onChange={(event)=> setEnquiryData({...enquiryData , numberOfAnalogOutput : event.target.value})}
                  />
                </div>  

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Analog I/P Voltage:
                    </div>
                    <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"0-10VDC", label:"0-10VDC"},
                    {value:"4-20mA", label:"4-20mA"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Analog I/P Voltage"}
                  onChange={(selectedOption)=> setEnquiryData({...enquiryData , analogInputVoltage : selectedOption.value})}
                  />
                </div> 


                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                        Analog O/P Voltage:
                    </div>
                    <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"0-10VDC", label:"0-10VDC"},
                    {value:"4-20mA", label:"4-20mA"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Analog O/P Voltage"}
                  onChange={(selectedOption)=> setEnquiryData({...enquiryData , analogOutputVoltage : selectedOption.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Type Of Communication :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Type Of Communication"
                    onChange={(event)=> setEnquiryData({...enquiryData , typeOfCommunication : event.target.value})}
                  />
                </div>  

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       VFD to MOTOR DISTANCE (Meter) :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="VFD to MOTOR DISTANCE (Meter)"
                    onChange={(event)=> setEnquiryData({...enquiryData , vfdToMotorDistance : event.target.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Encoder Supported :
                    </div>

                    <Select styles={selectComponentStyles}
                  options={[
                    {value:"YES", label:"YES"},
                    {value:"NO", label:"NO"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Encoder Supported"}
                  onChange={(selectedOption)=> {
                    if(selectedOption.label==="YES")
                    {
                      setShowTypeOptionVfd(true);
                    }
                    else
                    {
                      setShowTypeOptionVfd(false);
                    }
                    setEnquiryData({...enquiryData , encoderSupported : selectedOption.value})
                  }}
                  />
                </div> 

                {showTypeOptionVfd===true && 
                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Encoder Type :
                    </div>

                    <Select styles={selectComponentStyles}
                  options={[
                    {value:"TTL", label:"TTL"},
                    {value:"HTL", label:"HTL"},
                    {value:"Absolute", label:"Absolute"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Encoder Type"}
                  onChange={(selectedOption)=> setEnquiryData({...enquiryData , encoderType : selectedOption.value})}
                  />
                </div> }

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       I/P Choke Required :
                    </div>

                    <Select styles={selectComponentStyles}
                  options={[
                    {value:"YES", label:"YES"},
                    {value:"NO", label:"NO"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"I/P Choke Required"}
                  onChange={(selectedOption)=> setEnquiryData({...enquiryData , inputChokeRequired : selectedOption.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       O/P Choke Required :
                    </div>

                    <Select styles={selectComponentStyles}
                  options={[
                    {value:"YES", label:"YES"},
                    {value:"NO", label:"NO"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"O/P Choke Required"}
                  onChange={(selectedOption)=> setEnquiryData({...enquiryData , outputChokeRequired : selectedOption.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                    Number Of 4 Pole Motors Connected :
                    </div>

                  <input
                    type="number"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Number Of 4 Pole Motors Connected"
                    onChange={(event)=> setEnquiryData({...enquiryData , motorConnected : event.target.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Environmental Temperature (degree celcius) :
                    </div>
                    {/* <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Environmental Temperature (degree celcius)"
                    onChange={(event)=> setEnquiryData({...enquiryData , environmentalTemperature : event.target.value})}
                  /> */}
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"Less than 40 degree celcius", label:"Less than 40 degree celcius"},
                    {value:"More than 40 degree celcius", label:"More than 40 degree celcius"},
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Environmental Temperature (degree celcius)"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , environmentalTemperature : selectedOption.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Environmental Condition :
                    </div>
                    {/* <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Environmental Condition"
                    onChange={(event)=> setEnquiryData({...enquiryData , environmentalCondition : event.target.value})}
                  /> */}
                  <Select styles={selectComponentStyles}
                  
                  options={[
                    {value:"Clean", label:"Clean"},
                    {value:"Dusty", label:"Dusty"},
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Environmental Condition"}
                  onChange={(selectedOption)=>setEnquiryData({...enquiryData , environmentalCondition : selectedOption.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Braking Register :
                    </div>

                    <Select styles={selectComponentStyles}
                  options={[
                    {value:"YES", label:"YES"},
                    {value:"NO", label:"NO"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Braking Register"}
                  onChange={(selectedOption)=> setEnquiryData({...enquiryData , breakingRegister : selectedOption.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Altitude :
                    </div>
                    <Select styles={selectComponentStyles}
                  options={[
                    {value:"Less than 1000m", label:"Less than 1000m"},
                    {value:"More than 1000m", label:"More than 1000m"}
                  ]}
                  className='w-100 mt-2'
                  placeholder={"Altitude"}
                  onChange={(selectedOption)=> setEnquiryData({...enquiryData , altitude : selectedOption.value})}
                  />
                </div> 

                <div className="mt-3 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-3 display-flex">
                    <div className='w-100 px-0 display-flex-start fs-slight-small-css'>
                       Special Note :
                    </div>
                    <input
                    type="text"
                    className="mt-2 input-field-form-enquiry rounded w-100"
                    placeholder="Special Note"
                    onChange={(event)=> setEnquiryData({...enquiryData , vfdSpecialNote : event.target.value})}
                  />
                </div>                
            </>
            }

            



            

            <div className="mt-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-column">
                <button disabled={
                  validCompanyName===true &&
                  validContactPerson===true && 
                  validCompanyAddress===true &&
                  validEmail===true && 
                  validPhone===true &&
                  enquiryData.phone.length===10 ?
                  false : true 
                } 
                onClick={handleSubmitEnquiryForm} className="btn-special fs-medium-css rounded w-auto">
                    {buffering===true ? "Sending enquiry...":"Submit Enquiry"}
                </button>

                {validCompanyName===false ||
                  validContactPerson===false || 
                  validCompanyAddress===false ||
                  validEmail===false || 
                  validPhone===false ||
                  validCompanyName===null ||
                  validContactPerson===null || 
                  validCompanyAddress===null ||
                  validEmail===null || 
                  validPhone===null ||
                  enquiryData.phone.length!==10 ?<div className='w-100 px-0 m-0 display-flex-column'>
                  
                   <div className='text-center w-100 display-flex-center px-0 m-0 height-30px fs-small-css mt-3'>
                    Please fill atleast all the * details {" "} 
                   {window.innerWidth>900? <span className="mx-1 px-0 w-auto text-success">CORRECTLY</span> : " CORRECTLY "}
                    to CLICK ME!
                </div>
                <div className='text-center w-100 display-flex-center px-0 m-0 height-30px fs-small-css mt-1'>
                    Also, please fill {' '} 
                    {window.innerWidth>900? <span className="mx-1 px-0 w-auto text-success">QTY</span> : " QTY "}
                    of Product To Send Enquiry!
                </div>
                </div>
                
                :null}
            </div>
        </div>
    </div>
  )
}
