import React, { useEffect } from "react";
// import { Route, Routes } from "react-router-dom";
import LandingPage from "./LandingPage";
import AboutUs from "./AboutUs";
import WhyChooseUs from "./WhyChooseUs";
// import OurProducts from "./OurProducts";
// import WhatDependsOnPrice from "./WhatDependsOnPrice";
import Testimonials from "./Testimonials";
// import LatestArticlesAndNews from "./LatestArticlesAndNews";
import Footer from "./Footer";
import Partners from "./Partners";

export default function HomePage() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <>
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-0 row px-0 display-flex">
        {/* <LandingPage /> */}
        <AboutUs />
        <WhyChooseUs />
        {/* <OurProducts /> */}
        {/* <WhatDependsOnPrice /> */}
        {/* <Testimonials /> */}
        <Partners />
        {/* <LatestArticlesAndNews /> */}
        <Footer />
    </div>
    
    </>
  );
}
