import React from 'react'

export default function PrivacyContent() {
   
  return (
    <div className='py-5 bg-theme-grey color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <div className='text-align-justify col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start fs-slight-small-css'>
        Welcome to www.dvps.in.net. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website www.dvps.in.net.
        </div>

        <div className='mt-4 color-theme-dark-blue fw-bold col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
            Information We Collect
        </div>
        <div className='mt-3 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
            a. Information You Provide:
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        {/* <i className="px-0 w-auto me-2 ms-0 color-theme-blue fa-solid fa-circle-chevron-right" />  */}
        - Personal Information: When you sign up for an account, subscribe to our newsletter, make a purchase, or contact us, we collect personal information such as your name, email address, phone number, and payment details.
        
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        {/* <i className="px-0 w-auto me-2 ms-0 color-theme-blue fa-solid fa-circle-chevron-right" /> */}
        - Feedback: Any feedback or comments you provide.
        
        </div>

        <div className='mt-3 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
            b. Information Automatically Collected::
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        - Log Data: We collect information that your browser sends whenever you visit our Site. This may include your computer’s Internet Protocol (IP) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.
        
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        - Cookies and Tracking Technologies: We use cookies and similar tracking technologies to track activity on our Site and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
        
        </div>

        <div className='mt-4 color-theme-dark-blue fw-bold col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        How We Use Your Information
        </div>
        <div className='mt-3 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        We use the information we collect in the following ways:
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        - To provide, operate, and maintain our Site.        
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        - To improve, personalize, and expand our Site.        
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        - To understand and analyze how you use our Site.        
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        - To develop new products, services, features        
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        - These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
        
        </div>

        <div className='mt-4 color-theme-dark-blue fw-bold col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        Security of Your Information
        </div>
        <div className='fw-normal mt-3 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
        </div>

        <div className='mt-4 color-theme-dark-blue fw-bold col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        Your Data Protection Rights
        </div>
        <div className='mt-3 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        Depending on your location, you may have the following rights regarding your personal data:
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        The right to access – You have the right to request copies of your personal data.       
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.      
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        The right to erasure – You have the right to request that we erase your personal data, under certain conditions.      
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.      
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        The right to object to processing –You have the right to object to our processing of your personal data, under certain conditions.      
        </div>
        <div className='mt-2 col-11 fw-normal col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
To exercise any of these rights, please contact us at our provided contact information. We may need to verify your identity before responding to your request. 
        </div>


        <div className='mt-4 color-theme-dark-blue fw-bold col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        Changes to This Privacy Policy
        </div>
        <div className='fw-normal mt-3 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </div>


        <div className='mt-4 color-theme-dark-blue fw-bold col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        Third-Party Links
        </div>
        <div className='fw-normal mt-3 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-start'>
        Our Site may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </div>
    </div>
  )
}
