import React, { useEffect, useState } from "react";
import Select from "react-select";
import PbwrSeriesCat from "./pbwr-series.pdf";
import CSeriesCat from "./c-series.pdf";
import FSeriesCat from "./f-series.pdf";
import KSeriesCat from "./k-series.pdf";
import JSeriesCat from "./j-series.pdf";

import MSeriesCatalogue from "./pbl-m-series.pdf";

export default function GearedMotorContentMod() {
  const productsData = [
    {
      title: "M-Series",
      image: "https://dvps.in.net/dvps_images/m-series.jpg",
      subTitle: "Helical Inline Geared Motor",
      specifications: [
        { key: "Input Power", value: "Up to 160kW" },
        { key: "Output Torque ", value: "Up to 20,000Nm" },
        { key: "Gear Ratios", value: "Up to 20,000:1" },
        { key: "Mounting Options", value: "Foot & Flange" },
      ],
      catalogueToDownload : MSeriesCatalogue
    },
    {
      title: "PBWR-Series",
      image: "https://dvps.in.net/dvps_images/pbwr-series.jpg",
      subTitle: "Worm Geared Motor",
      specifications: [
        { key: "Input Power", value: " Up to 15kW" },
        { key: "Output Torque ", value: " Up to 1760Nm" },
        { key: "Gear Ratios", value: "Up to 100:1" },
        { key: "Mounting Options", value: "Foot & Flange" },
      ],
      catalogueToDownload :PbwrSeriesCat
    },
    {
      title: "C-Series",
      image: "https://dvps.in.net/dvps_images/c-series.jpg",
      subTitle: "Helical Worm Geared Motor",
      specifications: [
        { key: "Input Power", value: "Up to 45kW" },
        { key: "Output Torque ", value: "Up to 10,000Nm" },
        { key: "Gear Ratios", value: "Up to 16,000:1" },
        { key: "Mounting Options", value: "Flange, Foot & Torque Arm" },
      ],
      catalogueToDownload : CSeriesCat
    },
    {
      title: "F-Series",
      image: "https://dvps.in.net/dvps_images/f-series.jpg",
      subTitle: "Helical Parallel Geared Motor",
      specifications: [
        { key: "Input Power", value: " Up to 45kW" },
        { key: "Output Torque ", value: "Up to 10,000Nm" },
        { key: "Gear Ratios", value: "Up to 20,000:1" },
        { key: "Mounting Options", value: "Foot, Flange & Shaft Mounting" },
      ],
      catalogueToDownload : FSeriesCat
    },
    {
      title: "K-Series",
      image: "https://dvps.in.net/dvps_images/k-series.jpg",
      subTitle: "Helical Bevel Geared Motor",
      specifications: [
        { key: "Input Power", value: "Up to 132kW" },
        { key: "Output Torque ", value: "Up to 50,000Nm" },
        { key: "Gear Ratios", value: "Up to 10,000:1" },
        { key: "Mounting Options", value: "Flange, Foot & Torque Arm" },
      ],
      catalogueToDownload : KSeriesCat
    },
    {
      title: "J-Series",
      image: "https://dvps.in.net/dvps_images/j-series.jpg",
      subTitle: "Parallel Shaft Mounted Speed Reducer",
      specifications: [
        { key: "Input Power", value: "Up to 55kW" },
        { key: "Output Torque ", value: "Up to 5,000Nm" },
        { key: "Gear Ratios", value: "Up to 32,000:1" },
        { key: "Mounting Options", value: " Shaft Mounting" },
      ],
      catalogueToDownload : JSeriesCat
    },
    {
      title: "PL-Series",
      image: "https://dvps.in.net/dvps_images/pl-series.jpg",
      subTitle: "Planetry Geared Motor",
      specifications: [
        { key: "Input Power", value: "Up to 90kW" },
        { key: "Output Torque ", value: " Up to 65,000Nm" },
        {
          key: "Gear Ratios",
          value: "3.6:1 to 3200:1-Single/Double Reduction",
        },
        { key: "Mounting Options", value: "Foot & Flange" },
      ],
      outputOptions: [
        " Output shaft with keyway",
        "Hollow output shaft to suit connection with shrink disc",
        "Output shaft with external spline",
        "Output shaft with internal spline",
      ],
      catalogueToDownload : null
    },
  ];

  const [product, setProduct] = useState({});
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setProduct(productsData[0]);
    document.getElementById("m-series-btn").focus();
  }, []);
  const applications = [
    "Aerators",
    "Extrusion",
    "Palletiing",
    "Mixers Moulding",
    "Feeders",
    "Paper Mill Drive",
    "Conveyors",
    "Cranes",
    "Stacker Reclaimer",
  ];
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="pb-5 mb-0 bg-theme-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="mt-5 display-on-small-screen-center col-10 col-sm-10 col-md-8 col-lg-12  mb-5  col-xl-12 row px-0">
          <div className="w-100 color-theme-dark-blue px-0 mt-0 fw-bold fs-medium-css">
            Select Product:
          </div>
            <Select 
            options={[
              {index:0 , label:"M Series"},
              {index:1 , label:"PBWR Series"},
              {index:2 , label:"C Series"},
              {index:3 , label:"F Series"},
              {index:4 , label:"K Series"},
              {index:5 , label:"J Series"},
              {index:6 , label:"PL Series"}            
            ]}
            onChange={(selectedProduct)=> setIndex(selectedProduct.index) }
            className="w-100 mt-2  px-0 "
            />
        </div>
       
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
          

          <div className=" col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 row px-0 display-flex-align-items-top">
          
            
            <div className="mt-5 position-sticky-top-left col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row px-0 display-flex">
              <img
                src={productsData[index].image}
                className="w-100 px-4"
                alt={`${productsData[index].title} image`}
              />
            </div>

            <div className="mt-5  col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row px-4 display-flex">

            <div className="fw-bold color-theme-dark-blue fs-large-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                {productsData[index].title}
              </div>

              <div className="fw-bold color-theme-blue fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                {productsData[index].subTitle}
              </div>

              <div className="mt-3 fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Specifications
              </div>

              {productsData[index].specifications.map((ele, index) => (
                <div
                  key={index}
                  className="fs-slight-small-css mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start"
                >
                  <i className="px-0 w-auto me-2 ms-0 color-theme-blue fa-solid fa-circle-chevron-right" />
                  <span className="w-auto me-1 ms-0 fw-bold px-0">
                    {ele.key}
                  </span>{" "}
                  : {ele.value}
                </div>
              ))}

              {productsData[index].outputOptions && (
                <>
                  <div className="mt-3  fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                    Output Options
                  </div>

                  {productsData[index].outputOptions.map((ele, index) => (
                    <div
                      key={index}
                      className="mt-2 fs-slight-small-css  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start"
                    >
                      <i className="px-0 w-auto me-2 ms-0 color-theme-blue fa-solid fa-circle-chevron-right" />
                      {ele}
                    </div>
                  ))}
                </>
              )}

              {productsData[index].catalogueToDownload!==null && 
              <div className="mt-4 col-12 col-sm-12 col-md-12 row px-0 display-flex-start col-lg-12 col-xl-12 ">
              <a
              href={productsData[index].catalogueToDownload}
               download={`${productsData[index].title} ${productsData[index].subTitle} Catalogue`}
               className="btn-special w-auto text-decoration-none">
                Download Catalogue
               </a>
              </div>}
            </div>
          </div>

          <div className="border-left-grey bg-theme-white py-0 mt-0 col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 row px-0 display-on-large-screen-align-items-top">
          <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 select-product row display-flex-start fs-medium-css">
              Select Product <i className="fa-regular w-auto fa-square-caret-down"></i>
            </div>
            <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <button
                onClick={() => {
                  setIndex(0);
                }}
                id="m-series-btn"
                className="w-100 btn-navbar-product"
              >
                M Series<span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">-Helical Inline Geared Motor</span>
              </button>
            </div>
            <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <button
                onClick={() => {
                  setIndex(1);
                }}
                className="w-100 btn-navbar-product"
              >
                PBWR Series<span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">-Worm Geared Motor</span>
              </button>
            </div>
            <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <button
                onClick={() => {
                  setIndex(2);
                }}
                className="w-100 btn-navbar-product"
              >
                C Series<span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">-Helical Worm Geared Motor</span>
              </button>
            </div>
            <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <button
                onClick={() => {
                  setIndex(3);
                }}
                className="w-100 btn-navbar-product"
              >
                F Series<span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">-Helical Parallel Geared Motor</span>
              </button>
            </div>
            <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <button
                onClick={() => {
                  setIndex(4);
                }}
                className="w-100 btn-navbar-product"
              >
                K Series<span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">-Helical Bevel Geared Motor</span>
              </button>
            </div>
            <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <button
                onClick={() => {
                  setIndex(5);
                }}
                className="w-100 btn-navbar-product"
              >
                J Series<span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">-Parallel Shaft Mounted Speed Reducer </span>
              </button>
            </div>
            <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <button
                onClick={() => {
                  setIndex(6);
                }}
                className="w-100 btn-navbar-product"
              >PL Series
                <span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">-Planetry Geared Motor</span>
              </button>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}
