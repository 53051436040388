import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import Logo from "../components/dvps-logo-mod.jpg";
import DvpsDiagram from "../components/dvps_diagram.png";

const styles = StyleSheet.create({
  pdfViewer: {
    height: "100vh",
    width: "100vw",
  },
  document: {
    height: "100vw",
  },
  page: {
    padding: "0.5cm ",
    position: "relative"
  },
  header: {
    // border: "5px solid red",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "0px",
  },
  logoContainer: {
    // border: "5px solid red",
    width: "25%",
    padding: "10px",
  },
  logo: {
    width: "100%",
  },
  headerContainer: {
    width: "50%",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  headingfield: {
    marginTop: "0.4cm",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
  },
  headingLine1: {
    fontWeight: "bold",
    fontSize: "0.6cm",
    color: "#003b66",
  },
  headingLine2: {
    fontSize: "0.7cm",
    color: "#003b66",
  },
  headingfieldHalf: {
    fontSize: "0.3cm",
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "row",
    textAlign: "center",
  },
  row: {
    width: "100%",
    border: "1px solid black",
    padding: "0.2cm",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  rowFull: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  rowHalf: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  rowAttribute: {
    fontWeight: "heavy",
    fontSize: "0.4cm",
    width: "auto",
  },
  headingRowAttribute: {
    fontWeight: "heavy",
    fontSize: "0.5cm",
    width: "auto",
  },
  rowValue: {
    width: "auto",
    fontWeight: "normal",
    fontSize: "0.4cm",
    marginLeft: "0.3cm",
  },
  headingRow: {
    width: "100%",
    // border: "1px solid black",
    padding: "0.2cm",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    backgroundColor: "#003b66",
    color: "white",
    marginTop: "0.3cm",
  },
});

export default function EnquiryForm(props) {
  return (
    // <PDFViewer style={styles.pdfViewer}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View
            style={{
              position: "absolute",
              opacity: 0.3,
              transform: "rotate(-45deg)",
              top: "30%",
              left: "50%",
              width: "100%",
              transformOrigin: "center",
            }}
          >
            <Text style={{
              width: "100%",
              fontSize: "0.6cm"
            }}>
                www.dvps.in.net</Text>
          </View>

          <View
            style={{
              position: "absolute",
              opacity: 0.3,
              transform: "rotate(-45deg)",
              top: "60%",
              left: "50%",
              width: "100%",
              transformOrigin: "center"
            }}
          >
            <Text style={{
              width: "100%",
              fontSize: "0.6cm"
            }}>
                www.dvps.in.net
            </Text>
          </View>
          <View style={styles.header}>
            <View style={styles.logoContainer}>
              <Image style={styles.logo} src={Logo} />
            </View>

            <View style={styles.headerContainer}>
              <View style={styles.headingfield}>
                <Text style={styles.headingLine1}>DV POWER SOLUTIONS</Text>
              </View>

              <View style={styles.headingfield}>
                <Text style={styles.headingLine2}>Enquiry Form</Text>
              </View>

              <View style={styles.headingfield}>
                <Text style={styles.headingfieldHalf}>sales@dvps.in.net</Text>
                <Text style={styles.headingfieldHalf}>www.dvps.in.net</Text>
              </View>

              <View style={styles.headingfield}>
                <Text style={styles.headingfieldHalf}>Enquiry ID: {props.enquiryId}</Text>
                <Text style={styles.headingfieldHalf}>Date: {props.date}</Text>
              </View>
            </View>

            <View style={styles.logoContainer}>
              <Image style={styles.logo} src={DvpsDiagram} />
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Company Name:</Text>
              <Text style={styles.rowValue}>{props.enquiryData.companyName}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowHalf}>
              <Text style={styles.rowAttribute}>Contact Person :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.contactPerson}</Text>
            </View>

            <View style={styles.rowHalf}>
              <Text style={styles.rowAttribute}>Mob :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.phone}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Company Address:</Text>
              <Text style={styles.rowValue}>{props.enquiryData.companyAddress}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Email Id:</Text>
              <Text style={styles.rowValue}>{props.enquiryData.email}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowHalf}>
              <Text style={styles.rowAttribute}>Application :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.application}</Text>
            </View>

            {/* <View style={styles.rowHalf}>
              <Text style={styles.rowAttribute}>Project No. :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.projectNumber}</Text>
            </View> */}
          </View>

          {props.enquiryData.gearboxQty!=="" && <><View style={styles.headingRow}>
            <View style={styles.rowHalf}>
              <Text style={styles.headingRowAttribute}>GearBox Data </Text>
            </View>

            <View style={styles.rowHalf}>
              <Text style={styles.rowAttribute}>QTY :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxQty}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>GearBox Type :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxType}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>GearBox Input :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxInput}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>
                GearBox Output Shaft Details :
              </Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxOutputShaft}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>GearBox Mounting :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxMounting}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>
                GearBox Mounting Position :
              </Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxMountingPosition}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>
                GearBox Input Power (kW/HP) :
              </Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxInputPower}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>
                GearBox Output Shaft Dimension :
              </Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxOutputShaftDimension}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Output RPM  :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.outputRpm}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Output Ratio  :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.outputRatio}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Output Torque :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.outputTorque}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Service Factor :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.serviceFactor}</Text>
            </View>
          </View>
          
          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Special Note :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.gearboxSpecialNote}</Text>
            </View>
          </View>
          </>}

          {props.enquiryData.motorQty!=="" &&  <><View style={styles.headingRow}>
            <View style={styles.rowHalf}>
              <Text style={styles.headingRowAttribute}>Motor Data </Text>
            </View>

            <View style={styles.rowHalf}>
              <Text style={styles.rowAttribute}>QTY :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorQty}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Frame :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorFrame}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Power (kW/HP) :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorPower}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor RPM :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorRpm}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Current :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorCurrent}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>IP Protection Class :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.ipProtectionClass}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Ambient Temperature :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.ambientTemperature}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Voltage :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorVoltage}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Pole :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorPole}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Body :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorBody}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Duty :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorDuty}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Connection :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorConnection}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Frequency :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorFrequency}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Efficiency :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorEfficiency}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Mounting :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorMounting}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Brake Motor :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.brakeMotor}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Force Cooling FAN :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.forceCoolingFan}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Encoder Option :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.encoderOption}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Operation :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorOperation}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Insulation Class :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.insulationClass}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Special Note :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorSpecialNote}</Text>
            </View>
          </View></>}

          {props.enquiryData.vfdQty!=="" && <>
          <View style={styles.headingRow}>
            <View style={styles.rowHalf}>
              <Text style={styles.headingRowAttribute}>VFD Data </Text>
            </View>

            <View style={styles.rowHalf}>
              <Text style={styles.rowAttribute}>QTY :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.vfdQty}</Text>
            </View>
          </View>

          {/* <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>
                Motor Power / Voltage / Ampere :
              </Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorPowerVoltageAmpere}</Text>
            </View>
          </View> */}

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>VFD Power (kW/HP) :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.vfdPower}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>VFD I/P Voltage :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.vfdInputVoltage}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Number Of Digital I/P :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.numberOfDigitalInput}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Number Of Digital O/P :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.numberOfDigitalOutput}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Number Of Relay O/P :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.numberOfRelayOutput}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Number Of Analog I/P :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.numberOfAnalogInput}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Number Of Analog O/P :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.numberOfAnalogOutput}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Analog I/P Voltage :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.analogInputVoltage}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Analog O/P Voltage :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.analogOutputVoltage}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Type Of Communication :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.typeOfCommunication}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>
                VFD to Motor Distance (Meter) :
              </Text>
              <Text style={styles.rowValue}>{props.enquiryData.vfdToMotorDistance}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Encoder Supported :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.encoderSupported}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Encoder Type :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.encoderType}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>I/P Choke Required :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.inputChokeRequired}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>O/P Choke Required :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.outputChokeRequired}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Motor Connected :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.motorConnected}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>
                Environmental Temperature :
              </Text>
              <Text style={styles.rowValue}>{props.enquiryData.environmentalTemperature}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Environmental Condition :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.environmentalCondition}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Braking Register :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.breakingRegister}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Altitude :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.altitude}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.rowFull}>
              <Text style={styles.rowAttribute}>Special Note :</Text>
              <Text style={styles.rowValue}>{props.enquiryData.vfdSpecialNote}</Text>
            </View>
          </View></>}

        </Page>
      </Document>
    // </PDFViewer>
  );
}
