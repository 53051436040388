import React from "react";
import VfdCatalogue from "./vfd-catalogue.pdf";

export default function PblVfdContent() {
  const partners=[
    {
      image:"https://dvps.in.net/dvps_images/Radicon.jpg",
      alt:"Radicon Powerbuild Logo"
    },
    {
      image:"https://dvps.in.net/dvps_images/Delta.jpg",
      alt:"DELTA Logo"
    }
  ];
  return (
    <div className="pb-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="my-0 py-5 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
          <img
            src="https://dvps.in.net/dvps_images/pbl-vfd.jpg"
            className="w-50 px-3"
            alt="VFD Image 1"
          />
          <img
            src="https://dvps.in.net/dvps_images/VFD 2.jpg"
            className="w-25 px-3"
            alt="VFD Image 2"
          />
          {/* <img
            src="https://dvps.in.net/dvps_images/VFD 1.jpg"
            className="w-25 px-3"
            alt=""
          /> */}
        </div>

        <div className="mt-4 fs-larger-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
          VFD
        </div>
        <div className="w-75 px-0 mt-4 rounded display-flex-start me-auto">
          <div className="px-0 m-0 line-theme-blue rounded"></div>
        </div>

        <div className="color-theme-dark-blue fw-normal mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row pe-5 display-flex-start mx-0 ps-0">
          We provide complete solution with VFD's, Soft starters & Electrical
          panels. VFD's are able to efficiently control motor speed, maintain
          constant torque and save energy. AC Drives accurately control speed
          and torque, smoothly handle an increased load, and provide numerous
          custom control and configuration operating modes.
        </div>

        <div className="color-theme-dark-blue fw-normal mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row pe-5 display-flex-start mx-0 ps-0">
          PBL Centralized Drive (CD) Series is the Standard General Purpose VFDs
          with built-in High Capacity than Normal Duty Drives
        </div>

        <div className="color-theme-dark-blue fw-normal mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row pe-5 display-flex-start mx-0 ps-0">
          A Variable Frequency Drive can regulate a motor's precise speed in
          contrast to a soft starter, which can simply accelerate and decelerate
          the motor
        </div>

        <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              <a 
        href={VfdCatalogue} 
        download={"VFD Catalogue"} 
        className='text-decoration-none btn-special w-auto'
        >Download Catalogue 
        <i className="fa-solid fa-download"></i>
        </a>
              </div>

        <div className="mt-5 fs-large-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
          Features
        </div>
        <div className="w-75 px-0 mt-4 rounded display-flex-start me-auto">
          <div className="px-0 m-0 line-theme-blue rounded"></div>
        </div>

        <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ">
          <i className="fa-solid display-on-large-screen fa-circle fs-small-css me-2 w-auto color-theme-blue"></i>{" "}
          Product Range : 0.4 kW to 400 kW
        </div>

        <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ">
          <i className="fa-solid display-on-large-screen fa-circle fs-small-css me-2 w-auto color-theme-blue"></i>{" "}
          Voltage : 1PH 230 VAC ; 3PH 230 VAC & 415 VAC
        </div>

        <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ">
          <i className="fa-solid display-on-large-screen fa-circle fs-small-css me-2 w-auto color-theme-blue"></i>{" "}
          Output Frequency Range : 0-400 Hz & 0-600 Hz
        </div>

        <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ">
          <i className="fa-solid display-on-large-screen fa-circle fs-small-css me-2 w-auto color-theme-blue"></i>{" "}
          Control Mode : v/f control, Sensor-less Vector control & Torque
          control
        </div>

        <div className="mt-5 fs-large-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
          Our Affiliations
        </div>
        <div className="w-75 px-0 mt-4 rounded display-flex-start me-auto">
          <div className="px-0 m-0 line-theme-blue rounded"></div>
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start my-5 bg-theme-grey">
         {partners.map((ele,index)=> 
         <div key={index} className="col-11 col-sm-11 col-md-4 col-lg-4 col-xl-3 row mx-2 partner-card my-4 p-3 display-flex">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row  bg-theme-white partner-rounded p-2 display-flex">
              <img
                src={ele.image}
                className="w-100 partner"
                alt={ele.alt}
              />
            </div>
          </div>)}
        </div>
      </div>
    </div>
  );
}
