import React, { useEffect } from 'react'
import InductionMotorContent from './InductionMotorContent'
import Header from './Header'
import Footer from './Footer';

export default function InductionMotor() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Header title={"Induction Motor"} />

        <InductionMotorContent />


        <Footer />

    </div>
  )
}
