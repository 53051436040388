import React, { useEffect, useState } from "react";
import Select from "react-select";

import Catalogue from "./LEDL Booklet.pdf";

export default function InductionMotorContent() {
  const technicalSpecifications = [
    { key: "Governing Standard", value: "Is12615 / IEC 60034" },
    { key: "Protection Class", value: "IP55, Continuous Duty (S1), " },
    {
      key: "Operating Voltage",
      value: "415 +/- 10%, 50Hz +/- 5%, 3Ph AC Supply",
    },
    { key: "Enclosure", value: "Totally Enclosed Fan cooled (TEFC)" },
    { key: "Frame", value: "IEC 63 - IEC 280" },
    { key: "Pole", value: "2,4,6,8" },
    { key: "Terminal Box Potion", value: "Top" },
    { key: "Insulation Class", value: "F with temerature rise" },
    { key: "Limited to Class", value: "B" },
    { key: "Ambient", value: "-10 / +50'C" },
    { key: "Altitude", value: "up to 1000m above MSL" },
    { key: "Range", value: "0.09kW - 90kW" },
    { key: "Degree of Protection ", value: "IP55" },
    { key: "Number of Leads", value: "3 / 6 leads" },
    { key: "Mounting", value: "B3,B5,B14,B35" },
  ];


    const applications = [
      "Pump",
      "Blower",
      "Machine Tool",
      "Fire fighting",
      "Fans",
      "Power Tools",
      "Compressors",
      "Crane",
      "Rolling Mill",
      "Steel Mill",
      "Paper Mill",
      "Poultry Farms",
      "Material Handling",
      "Agro Machines",
      "Textile Machines",
      "Wind Energy",
      "Conveyors",
      "Hydraulic Power Packs",
      "Food Processing",
      "Crusher"
    ];


    const optionalFeatures = [
      {key:"Insulation Class", value:"H"},
      {key:"Voltage Range", value:"220V up to 690V"},
      {key:"Frequency", value:"60 Hz"},
      {key:"Cyclic Duty Factor", value:"S3, S4, etc.,"},
      {key:"Service Factor ", value:" 1.05, 1.1,1.15, 1.2,1.3, etc.,"},
      {key:"Enclosure", value:"IP54, 65, 66"},
      {key:"Terminal Box Potion", value:" RHS/LHS"},
      {key:"Inverter Duty", value:""},
      {key:"Higher rating in lower frame size ", value:""},
      {key:"Special / Insulated Bearings ", value:""},
      {key:"RTD & PTC", value:""},
      {key:"Forced cooling above 112 Frame", value:""},
      {key:"Anti - corrosive treatment C3M, C4H etc", value:""},
      {key:"Low vibration", value:""},
      {key:"Encoder mounting Provision", value:""}
    ];

  const productsData = [
    {
      title: "B3 Foot Mount",
      image: "https://dvps.in.net/dvps_images/b3-induction-motor.jpg",
      subTitle: "Induction Motor",
    },
    {
      title: "B5 Flange Mount",
      image: "https://dvps.in.net/dvps_images/b5-induction-motor.jpg",
      subTitle: "Induction Motor",
    },
    {
      title: "B35 Foot Cum Flange Mount",
      image: "https://dvps.in.net/dvps_images/b35-induction-motor.jpg",
      subTitle: "Induction Motor",
    },
  ];

  // const [product, setProduct] = useState({});
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // setProduct(productsData[0]);
    document.getElementById("m-series-btn").focus();
  }, []);
  return (
    <div className="pt-0 bg-theme-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">

        <div className="display-on-small-screen-center col-10 col-sm-10 col-md-8 col-lg-12  mb-5  col-xl-12 row px-0 ">
        <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <a 
        href={Catalogue} 
        download={"Motor Booklet"} 
        className='text-decoration-none btn-special w-auto'
        >Download Catalogue 
        <i className="fa-solid fa-download"></i>
        </a>
              </div>
        
        <div className="w-100 color-theme-dark-blue px-0 mt-2 fw-bold fs-medium-css">
            Select Product:
          </div>
        <Select 
            options={[
              {index:0 , label:"B3 Foot Mount"},
              {index:1 , label:"B5 Flange Mount"},
              {index:2 , label:"B35 Foot Cum Flange Mount"}            
            ]}
            onChange={(selectedProduct)=> setIndex(selectedProduct.index) }
            className="w-100 mt-2  px-0"
            />
        </div>

        <div className="pb-5 mb-0 bg-theme-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
            <div className=" col-12 col-sm-12 col-md-11 col-lg-9 col-xl-9 row px-0 display-flex-align-items-top">
              <div className="mt-5 position-sticky-top-left col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row px-0 display-flex">
                <img
                  src={productsData[index].image}
                  className="w-75 px-4"
                  alt={`${productsData[index].title} image`}
                />
              </div>

              <div className="mt-5  col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row px-4 display-flex">
                <div className="position-sticky-top bg-theme-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex py-4 m-0">
                  <div className="fw-bold color-theme-dark-blue fs-large-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                    {productsData[index].title}
                  </div>
                  <div className="fw-bold color-theme-blue fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                    {productsData[index].subTitle}
                  </div>
                </div>

                <div className="mt-4 fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-medium-css">
                  Specifications
                </div>

                {technicalSpecifications.map((ele, index) => (
                  <div
                    key={index}
                    className="fs-slight-small-css mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start"
                  >
                    <i className="px-0 w-auto me-2 ms-0 color-theme-blue fa-solid fa-circle-chevron-right" />
                    <span className="w-auto me-1 ms-0 fw-bold px-0">
                      {ele.key}
                    </span>{" "}
                    : {ele.value}
                  </div>
                ))}


<div className="mt-4 fw-bold color-theme-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-medium-css">
                  Optional Features
                </div>

                {optionalFeatures.map((ele, index) => (
                  <div
                    key={index}
                    className="fs-slight-small-css mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start"
                  >
                    <i className="px-0 w-auto me-2 ms-0 color-theme-blue fa-solid fa-circle-chevron-right" />
                    <span className="w-auto me-1 ms-0 fw-bold px-0">
                      {ele.key}
                    </span>{" "}
                    {ele.value!=="" && ":"} {ele.value}
                  </div>
                ))}
              </div>
            </div>

            <div className="display-on-large-screen-align-items-top position-sticky-top-right border-left-grey bg-theme-white py-0 mt-5 col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 row px-0">
              <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 select-product row display-flex-start fs-medium-css">
                Select Product{" "}
                <i className="fa-regular w-auto fa-square-caret-down"></i>
              </div>
              <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <button
                  onClick={() => {
                    setIndex(0);
                  }}
                  id="m-series-btn"
                  className="w-100 btn-navbar-product"
                >
                  B3 Foot Mount
                  <span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">
                    -Induction Motor
                  </span>
                </button>
              </div>

              <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <button
                  onClick={() => {
                    setIndex(1);
                  }}
                  id="m-series-btn"
                  className="w-100 btn-navbar-product"
                >
                  B5 Flange Mount
                  <span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">
                    -Induction Motor
                  </span>
                </button>
              </div>

              <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <button
                  onClick={() => {
                    setIndex(2);
                  }}
                  id="m-series-btn"
                  className="w-100 btn-navbar-product"
                >
                  B35 Foot Cum Flange Mount
                  <span className="w-auto px-0 ms-1 me-0 fs-small-css fw-normal fw-normal">
                    -Induction Motor
                  </span>
                </button>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <a 
        href={Catalogue} 
        download={"Motor Booklet"} 
        className='text-decoration-none btn-special w-100'
        >Download Catalogue 
        <i className="fa-solid fa-download"></i>
        </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
