import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();
  const navbarRef = useRef(null);
  const [serviceOptionsStatus, setServiceOptionsStatus] = useState("scale-y-0");
  const [navbarToggle , setNavbarToggle] = useState("scale-x-0");

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setServiceOptionsStatus("scale-y-0");
    }}

    useEffect(() => {
      if (serviceOptionsStatus==="scale-y-1") {
        document.addEventListener('click', handleClickOutside, true);
      } else {
        document.removeEventListener('click', handleClickOutside, true);
      }
  
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, [serviceOptionsStatus]);
  return (
    <>
      <div className="z-index-99 m-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-on-large-screen">
        {/* <div className="bg-theme-dark-blue color-theme-white fs-small-css fw-light py-1 m-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
          <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-10 row px-0 display-flex-end">
            <div className="py-0 col-12 col-sm-12 col-md-12 col-xl-7 col-lg-9 row px-0 display-flex">
              <span className="w-auto display-flex-center">
                <i className="fa-solid fa-phone me-1"></i> +91 9511892048
              </span>

              <span className="w-auto display-flex-center">
                <i className="fa-solid fa-envelope me-1"></i> sales@dvps.in.net
              </span>

              <span className="w-auto display-flex-center">
                <i className="fa-solid fa-clock me-1"></i> Mon-Sat 9:00 - 7:00
              </span>
            </div>
          </div>
        </div> */}

        <div className="navbar-shadow bg-theme-white py-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
          <div className="  col-12 col-sm-12 col-md-12 col-lg-11 col-xl-10 row px-0 display-flex-align-items-top-between ">
            <div className="m-0  col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 row px-0 display-flex-start">
              <img
                src="https://dvps.in.net/dvps_images/dvps-logo-mod.jpg"
                alt="DVPS LOGO"
                className="w-50  px-0 cursor-pointer"
                onClick={()=>navigate("/")}
              />
            </div>

            <div className="mx-0 mt-2 col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex-align-items-top-end ">
              <div className="w-20   row px-0 display-flex  ">
                <button
                  onClick={() => {navigate("/");
                    setServiceOptionsStatus("scale-y-0");
                  }}
                  className="btn-navbar  display-flex-column w-auto "
                >
                  HOME
                  <div className="w-100 px-0 btn-navbar-underline mt-2"></div>
                </button>
              </div>

              <div className="w-20   row px-0 display-flex ">
                <button
                  onClick={() => {navigate("/aboutus");
                    setServiceOptionsStatus("scale-y-0");}}
                  className="btn-navbar  display-flex-column w-auto "
                >
                  ABOUT US
                  <div className="w-100 px-0 btn-navbar-underline mt-2"></div>
                </button>
              </div>

              <div className="w-20   px-0 m-0">
                <div className="w-100  products-btn-div mx-0 row px-0 display-flex ">
                  <button
                    onMouseEnter={() => {
                      if (serviceOptionsStatus === "scale-y-0") {
                        setServiceOptionsStatus("scale-y-1");
                      } else {
                        setServiceOptionsStatus("scale-y-0");
                      }
                    }}
                    className="btn-navbar  products-btn display-flex-column w-auto "
                  >
                    <div className="w-100 display-flex p-0 m-0">
                      PRODUCTS
                      <i className={`ms-2 fa-solid fa-angle-down `}></i>
                    </div>
                    <div className="w-100 px-0 btn-navbar-underline mt-2"></div>
                  </button>
                </div>
                <div
                  ref={navbarRef} 
                  className={`${serviceOptionsStatus} w-150 px-0 py-2  products-dropdown mt-3 bg-theme-white display-flex-column`}
                >
                  <button
                    onClick={() => {
                      navigate("/inductionMotor");
                      setServiceOptionsStatus("scale-y-0");
                    }}
                    className="btn-navbar-internal -transparent w-100"
                  >
                    Induction Motor
                  </button>
                  <button
                    onClick={() => {
                      navigate("/gearedMotors");
                      setServiceOptionsStatus("scale-y-0");
                    }}
                    className="btn-navbar-internal -transparent w-100"
                  >
                    Geared Motor
                  </button>
                  <button
                    onClick={() => {
                      navigate("/pblVfd");
                      setServiceOptionsStatus("scale-y-0");
                    }}
                    className="btn-navbar-internal -transparent w-100"
                  >
                    VFD
                  </button>
                </div>
              </div>

              <div className="w-20  -transparent row px-0 display-flex ">
                <button
                  onClick={() => {navigate("/services");
                    setServiceOptionsStatus("scale-y-0");}}
                  className="btn-navbar display-flex-column w-auto "
                >
                  SERVICES
                  <div className="w-100 px-0 btn-navbar-underline mt-2"></div>
                </button>
              </div>

              <div className="w-20   -transparent row px-0 display-flex ">
                <button
                  onClick={() => {
                    navigate("/contactus");
                    setServiceOptionsStatus("scale-y-0");
                  }}
                  className="btn-navbar display-flex-column w-auto "
                >
                  CONTACT
                  <div className="w-100 px-0 btn-navbar-underline mt-2"></div>
                </button>
              </div>
            </div>
            <div className="mx-0 mt-2 col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 row px-0 display-flex-end">
              <button
                onClick={() => navigate("/enquiry")}
                className="btn-special myborder fs-small-css w-auto rounded "
              >
                PRODUCT ENQUIRY
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="z-index-99 py-2 bg-theme-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-on-small-screen">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row ps-2 pe-0 display-flex-between ">
          <div className="m-0 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 row px-0 display-flex-start">
            <button
            onClick={()=>setNavbarToggle("scale-x-1")} className="btn-navbar-toggle rounded w-auto">
              <i className="fa-solid fa-bars-staggered"></i>
            </button>
            <img
              src="https://dvps.in.net/dvps_images/dvps-logo-mod.jpg"
              alt="DVPS LOGO"
              className="w-50 px-2"
              onClick={()=>navigate("/")}
            />
          </div>

          <div className="m-0 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 row pe-3 ps-0 display-flex-end">
            <button
              onClick={() => navigate("/enquiry")}
              className="btn-special fs-slight-small-css w-auto rounded "
            >
              PRODUCT ENQUIRY!
            </button>
          </div>
        </div>

        <div className={`height-100vh col-10 small-screen-navbar ${navbarToggle} col-sm-10 col-md-8 col-lg-6 py-4 m-0 col-xl-12 row px-4 display-flex-align-items-top`}>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-end">
            <button
            onClick={()=>setNavbarToggle("scale-x-0")}
            className="btn-navbar-toggle rounded w-auto">
                <i className="fa-solid fa-xmark"></i>
            </button>
          </div>

          <div className="w-100 mt-0 px-0 display-flex-column">
                  <button onClick={()=>{
                    navigate("/");
                    setNavbarToggle("scale-x-0");
                    }} className="btn-navbar-internal mt-2 w-auto">Home</button>
                  <button onClick={()=>{
                    navigate("/aboutus");
                    setNavbarToggle("scale-x-0");
                    }} className="btn-navbar-internal mt-3 w-auto">About Us</button>
                  <button onClick={()=>{}} className="btn-navbar-internal mt-3 w-auto">Products 
                    <i className={`ms-2 fa-solid fa-angle-down `}></i>
                  </button>
                  <button onClick={()=>{
                    navigate("/inductionMotor");
                    setNavbarToggle("scale-x-0");
                    }} className="btn-navbar-internal-converse mt-1 w-auto">Induction Motor</button>
                  <button onClick={()=>{
                    navigate("/gearedMotors");
                    setNavbarToggle("scale-x-0");
                    }} className="btn-navbar-internal-converse mt-1 w-auto">Geared Motors</button>
                  <button onClick={()=>{
                    navigate("/pblVfd");
                    setNavbarToggle("scale-x-0");
                    }} className="btn-navbar-internal-converse mt-1 w-auto">VFD</button>
                  <button onClick={()=>{
                    navigate("/services");
                    setNavbarToggle("scale-x-0");
                    }} className="btn-navbar-internal mt-3 w-auto">Services</button>
                  <button onClick={()=>{
                    navigate("/contactus");
                    setNavbarToggle("scale-x-0");
                    }} className="btn-navbar-internal mt-3 w-auto">Contact</button>
          </div>
        </div>
      </div>
    </>
  );
}
