import React from "react";

export default function Partners() {
  const partners=[
    {
      image:"https://dvps.in.net/dvps_images/Radicon.jpg",
      alt:"Radicon Powerbuild Logo"
    },
    {
      image:"https://dvps.in.net/dvps_images/LEDL.jpg",
      alt:"LEDL Logo"
    }
  ];
  return (
    <div className="py-3 bg-theme-white col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className=" mt-0 fs-large-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
        Our Partners
      </div>
      <div className="w-25  display-flex px-0 mt-4 rounded">
        <div className="px-0 m-0 line-theme-blue rounded"></div>
      </div>

      <div className="col-12 mt-0 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-center">

        {partners.map((ele,index)=>
        <div key={index} className="col-11 col-sm-11 col-md-5 col-lg-4 col-xl-3 row partner-card mt-2 mx-1  py-4 px-0 display-flex">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0  bg-theme-white partner-rounded py-4 display-flex">
          <img src={ele.image} className="w-100 partner" 
          alt={ele.alt} />
        </div>
        </div>
        )}
      </div>
    </div>
  );
}
