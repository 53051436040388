import React from "react";

export default function AboutUs() {
  return (
    <div className="pb-0 pt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex-align-items-top">
        <div className="mt-3 position-relative py-5 col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 row px-0 display-flex">
          <img
            src="https://dvps.in.net/dvps_images/dots-7x7.png"
            alt="Decoration-1"
            className="img-dots-7x7-aboutus"
          />
          <img
            src="https://dvps.in.net/dvps_images/pl-series.jpg"
            alt="Our Product Geared Motor"
            className="w-100 height-30vh"
          />

          <img
            src="https://dvps.in.net/dvps_images/dots-4x4.png"
            className="img-dots-4x4-aboutus"
            alt="Decoration-2"
          />
        </div>

        <div className="mt-4 py-0 col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 row ps-0 pe-0  display-flex-align-items-top">
          <div className="fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
            WELCOME TO 
          </div>
          <div className="w-75 px-0 mt-2 rounded display-flex-start me-auto">
            <div className="px-0 m-0 line-theme-blue rounded"></div>
          </div>
          <div className="mt-3 fs-large-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
            DV POWER SOLUTIONS
          </div>

          <div className="color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row pe-0 ps-0    display-flex-start fw-normal fs-medium-small-css line-height mt-4">
            <strong className="w-auto pe-1 ps-0 fw-bold">DV Power Solutions</strong>{" "}
            <span className="w-auto fw-normal px-0">
              is supplier of electromechanical products like
            </span>
            <span className="w-auto fw-bold ps-0 pe-1 ">
              Gearmotors, Gearboxes, Motors, VFD’s, Soft Starters, Control
              Panels & more.
            </span>
            <span className="w-auto fw-normal px-0">
              We provide complete solutions in power transmission on various
              applications in industries like:
            </span>
            <span className="w-auto fw-bold ps-0 pe-1 ">
              Food Processing, Textile, Beverage, Chemical, Material Handling,
              Automobile, Metallurgy, Pharma, Recycling Plants, Logistics etc...
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
