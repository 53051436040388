import React from 'react'

export default function ApplicationsContent() {
    const applications = [
        "Pump",
        "Blower",
        "Machine Tool",
        "Fire fighting",
        "Fans",
        "Power Tools",
        "Compressors",
        "Crane",
        "Rolling Mill",
        "Steel Mill",
        "Paper Mill",
        "Poultry Farms",
        "Material Handling",
        "Agro Machines",
        "Textile Machines",
        "Wind Energy",
        "Conveyors",
        "Hydraulic Power Packs",
        "Food Processing",
        "Crusher"
    ];
    const industries = [
        "Agriculture",
        "Pharmaceutical",
        "Steel",
        "Material Handling",
        "Ceramics",
        "Food",
        "Packaging",
        "Ports & Terminals",
        "Sugar Processing",
        "Animal Feed & Poultry",
        "Chemical",
        "Foundry",
        "Paper",
        "Power",
        "Textile",
        "Biogas",
        "Crane",
        "HVAC",
        "Petrochemical",
        "Rubber",
        "Mining",
        "Cement",
        "Dairy",
        "Wastewater Treatment"
    ];
  return (
    <div className='bg-theme-white  py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <div className="mt-0 fs-larger-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-2 display-flex">
        Applications
      </div>
      <div className="w-50 display-flex px-0 mt-4 rounded">
        <div className="px-0 m-0 line-theme-blue rounded"></div>
      </div>
        <div className='mt-4 col-12 col-sm-12 col-md-11 px-4 pb-4 pt-2 bg-theme-white col-lg-10 col-xl-10 row display-flex-start'>
            {applications.map((ele,index)=>
                <div key={index} className='mt-2 mx-0  col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 row px-0 display-flex-start color-theme-black fs-slight-small-css'>
                    <i className="w-auto color-theme-blue px-0 me-1 ms-0 fa-solid fa-gear"></i> {ele}
                </div>
            )}
        </div>


        <div className="mt-5 fs-larger-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-2 display-flex">
        Industries
      </div>
      <div className="w-50 display-flex px-0 mt-4 rounded">
        <div className="px-0 m-0 line-theme-blue rounded"></div>
      </div>

        <div className='mt-4 col-12 col-sm-12  px-4 pb-4 pt-2 bg-theme-white col-md-11 col-lg-10 col-xl-10 row  display-flex-start'>
            {industries.map((ele,index)=>
                <div key={index} className='mt-2 mx-0  col-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 row px-0 display-flex-start color-theme-black fw-bold fs-slight-small-css'>
                    <i className="color-theme-blue w-auto px-0 me-1 ms-0 fa-solid fa-gear"></i> {ele}
                </div>
            )}
        </div>
    </div>
  )
}
