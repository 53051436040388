import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from './HomePage'
import Navbar from './Navbar'
import AboutUsPage from './AboutUsPage'
import Services from './Services'
import Enquiry from './Enquiry'
import ContactUs from './ContactUs'
import InductionMotor from './InductionMotor';
import GearedMotors from "./GearedMotors";
import PblVfd from './PblVfd'
import StickyButtons from './StickyButtons'
import Applications from './Applications'
import SiteMap from './SiteMap'
import Privacy from './Privacy'

export default function UserApp() {
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 m-0 display-flex'>
        <Navbar />
        <Routes>
            <Route exact path='/' element={<HomePage />} />
            <Route exact path='/aboutus' element={<AboutUsPage />} />
            <Route exact path='/services' element={<Services />} />
            <Route exact path='/enquiry' element={<Enquiry />} />
            <Route exact path='/contactus' element={<ContactUs />} />
            <Route exact path='/sitemap' element={<SiteMap />} />
            <Route exact path='/privacy' element={<Privacy />} />
            <Route exact path='/inductionMotor' element={<InductionMotor />} />
            <Route exact path='/gearedMotors' element={<GearedMotors />} />
            <Route exact path='/pblVfd' element={<PblVfd />} />
            <Route exact path='/applications' element={<Applications />} />

        </Routes>

        {/* <StickyButtons /> */}
    </div>
  )
}
