import React from "react";
import OurProducts from "./OurProducts";
import Footer from "./Footer";

export default function AboutUsContent() {
  return (
    <div className="col-12 z-index-1 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      {/* our-mission */}

      <div className="py-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex">
        <div className="mt-0 position-relative py-0 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 row px-0 display-flex">
            {/* <img src="https://dvps.in.net/dvps_images/dots-7x7.png" className="img-dots-7x7-aboutus" /> */}
          <img
            src="https://dvps.in.net/dvps_images/c-series.jpg"
            alt="Our Product Geared Motor"
            className="w-100 px-0"
          />
          
          {/* <img src="https://dvps.in.net/dvps_images/dots-4x4.png" className="img-dots-4x4-aboutus" /> */}
        </div>

        <div className="mt-5 py-0 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row ps-0 pe-0  display-flex-align-items-top">
          <div className="fs-larger-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
            WELCOME!
          </div>
          <div className="w-75 px-0 mt-4 rounded display-flex-start me-auto">
            <div className="px-0 m-0 line-theme-blue rounded"></div>
          </div>

          <div className="color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row pe-0 ps-0    display-flex-start mt-4">
                    DV Power Solutions is supplier of electromechanical products like 
          Gearmotors, Gearboxes, Motors, VFD’s, Soft Starters, Control Panels & more. We 
          provide complete solutions in power transmission on various applications in 
          industries like Food Processing, Textile, Beverage, Chemical, Material Handling, 
          Automobile, Metallurgy, Pharma, Recycling Plants, Logistics etc
          </div>

          <div className="color-theme-dark-blue fw-bold fs-slight-small-css  col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row pe-0 ps-0 display-flex-start mt-4">
                      We are a trusted source for Electromechanical Solutions and have consistently
            delivered safe, reliable, intelligent, and economical solutions to various customers. 
            We dealin following products to cater various Applications in the industries.
          </div>

          {/* <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-2 display-flex">
                    <div className="color-theme-blue fs-largest-css fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-center">
                        15 <sup className="display-on-large-screen w-auto mx-1">+</sup>
                    </div>

                    <div className="col-12 fs-slight-small-css color-theme-dark-blue col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                        Years Of Experience
                    </div>
                </div>

                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-0 display-flex">
                    <div className="color-theme-blue fs-largest-css fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-center">
                        20 <sup className="display-on-large-screen w-auto mx-0">+</sup>
                    </div>

                    <div className="col-12 fs-slight-small-css color-theme-dark-blue col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                        Happy Clients
                    </div>
                </div>

                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-2 display-flex">
                    <div className="color-theme-blue fs-largest-css fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-center">
                        28 <sup className="display-on-large-screen w-auto mx-1">+</sup>
                    </div>

                    <div className="col-12 fs-slight-small-css color-theme-dark-blue col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                        Partners
                    </div>
                </div>
          </div> */}
        </div>
      </div>
    </div> 


      <div className="py-5 col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 row px-0 display-flex">
        <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 row px-0 display-flex-align-items-top">
          <div className="mt-5 py-0 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row ps-0 pe-0  display-flex-align-items-top">
            <div className="fs-larger-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              Our Mission
            </div>
            <div className="w-75 px-0 mt-4 rounded display-flex-start me-auto">
              <div className="px-0 m-0 line-theme-blue rounded"></div>
            </div>

            <div className="color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row pe-0 ps-0  display-flex-start mt-4 fw-bold">
              Our team has been developing over the years. Today it includes
              only highly qualified specialists with experience and their own
              best practices. We boldly look into the future and confidently
              master new technologies, we always offer our customers different
              options and solutions. It’s convenient and comfortable with us!
            </div>
          </div>

          <div className="mt-5 position-relative py-5 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 row px-0 display-flex">
            <img
              src="https://dvps.in.net/dvps_images/bg-img3.png"
              alt="Decorative-1"
              className="img-dots-7x7-aboutus-page"
            />
            <img
              src="https://dvps.in.net/dvps_images/m-series.jpg"
              alt="Geared Motor"
              className="w-75"
            />

            <img
              src="https://dvps.in.net/dvps_images/bg-img4.png"
              className="img-dots-4x4-aboutus-page"
              alt="Decorative-2"
            />
          </div>
        </div>
      </div>

      <div className="py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex bg-theme-grey">
        <div className="mt-0 fs-larger-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-2 display-flex">
          Sequence Of Work
        </div>
        <div className="w-25  display-flex px-0 mt-4 rounded">
          <div className="px-0 m-0 line-theme-blue rounded"></div>
        </div>

        <div className="pb-5 col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 row px-0 display-flex">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-3 row px-0 display-flex">
              <div className="sequence-card margin-top-10vh py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-5 display-flex">
                <div className="sequence-card-number col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  01
                </div>

                <div className="mt-2 fw-bold fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Needs Assessment & Customization
                </div>

                <div className="mt-2 mb-3 fw-normal col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Analyze client requirements, customize motor and VFD solutions for efficiency, performance, and reliability.
                </div>

                <span className="sequence-card-square-1 sequence-card-square fs-medium-css w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>

                <span className="sequence-card-square-2 sequence-card-square fs-larger-css w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>

                <span className="sequence-card-square-3 sequence-card-square fs-slight-small-css fw-bold w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-3 row px-0 display-flex">
              <div className="sequence-card margin-top-20vh py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-5 display-flex">
                <div className="sequence-card-number col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  02
                </div>

                <div className="mt-2 fw-bold fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Design & Engineering Execution
                </div>

                <div className="mt-2 mb-3 fw-normal col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Develop tailored motor systems and VFD configurations, integrating advanced engineering techniques for optimal functionality.
                </div>

                <span className="sequence-card-square-1 sequence-card-square fs-medium-css w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>

                <span className="sequence-card-square-2 sequence-card-square fs-larger-css w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>

                <span className="sequence-card-square-3 sequence-card-square fs-slight-small-css fw-bold w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-3 row px-0 display-flex">
              <div className="sequence-card margin-top-30vh py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-5 display-flex">
                <div className="sequence-card-number col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                  03
                </div>

                <div className="mt-2 fw-bold fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Installation & After-Sales Support
                </div>

                <div className="mt-2 mb-3 fw-normal col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Implement solutions with precision, provide ongoing maintenance, troubleshooting, and performance optimization services.
                </div>

                <span className="sequence-card-square-1 sequence-card-square fs-medium-css w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>

                <span className="sequence-card-square-2 sequence-card-square fs-larger-css w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>

                <span className="sequence-card-square-3 sequence-card-square fs-slight-small-css fw-bold w-auto">
                  <i className="fa-regular fa-square"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
