import React, { useState } from "react";

export default function ContactUsContent() {
  const [contactUsData , setContactUsData] = useState({
    name:"",
    phone:"",
    typeOfService:"",
    message:""
  });
  const [validName , setValidName] = useState(null);
  const [validPhone , setValidPhone] = useState(null);
  const [validTypeOfService , setValidTypeOfService] = useState(null);
  const [validMessage , setValidMessage] = useState(null);
  return (
    <div className="bg-theme-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="mt-0 pt-0 col-12 col-sm-12 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-align-items-top">
        <div className="pb-5 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row px-4 display-flex">
          <div className="mt-4 fs-larger-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
            Our Address
          </div>

          <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-0 display-flex">
              <span className="contact-us-address-icon rounded-circle display-flex">
                <i className="fa-solid fa-location-dot"></i>
              </span>
            </div>

            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex">
                <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Company office
                </div>
                <div className="fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Truptikunj B-302, S.No.48/4, Manaji Nagar, 
                Narhe, Pune - 411041. Maharashtra, India
                </div>
            </div>
          </div>

          <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-0 display-flex">
              <span className="contact-us-address-icon rounded-circle display-flex">
                <i className="fa-solid fa-phone"></i>
              </span>
            </div>

            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex">
                <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Phone
                </div>
                <div className="fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                +91 9511892048 
                </div>
                <div className="mt-2 fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                 +91 7020278301
                </div>
            </div>
          </div>

          <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 row px-0 display-flex">
              <span className="contact-us-address-icon rounded-circle display-flex">
                <i className="fa-solid fa-envelope"></i>
              </span>
            </div>

            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex">
                <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Email
                </div>
                <div className="fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                sales@dvps.in.net 
                </div>
                <div className="mt-2 fw-normal mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                chandan.pawar@dvps.in.net
                </div>
            </div>
          </div>
        </div>

        <div className="py-4 bg-theme-blue-transparent col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row px-5 display-flex">
            <div className="fs-medium-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                Send Your Request!
            </div>
            <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <input
                type="text"
                className="mt-0 input-field-form rounded w-100"
                placeholder="Your Name"
                onChange={(event)=>{
                  if(event.target.value!=="")
                  {
                    setValidName(/^[A-Za-z\s]+$/.test(event.target.value));
                  }
                  else
                  {
                    setValidName(null);
                  }

                  if(validName===true)
                  {
                    setContactUsData({...contactUsData , name : event.target.value});
                  }
                }}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <input
                type="text"
                className="mt-0 input-field-form rounded w-100"
                placeholder="Phone"
                onChange={(event)=>{
                  if(event.target.value!=="")
                  {
                    setValidPhone(/^[0-9\s]+$/.test(event.target.value));
                  }
                  else
                  {
                    setValidPhone(null);
                  }

                  if(validPhone===true)
                  {
                    setContactUsData({...contactUsData , phone : event.target.value});
                  }
                }}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <input
                type="text"
                className="mt-0 input-field-form rounded w-100"
                placeholder="Type Of Service"
                onChange={(event)=>{
                  if(event.target.value!=="")
                  {
                    setValidTypeOfService(/^[A-Za-z\s]+$/.test(event.target.value));
                  }
                  else
                  {
                    setValidTypeOfService(null);
                  }

                  if(validTypeOfService===true)
                  {
                    setContactUsData({...contactUsData , typeOfService : event.target.value});
                  }
                }}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
              <textarea
                type="text"
                className="mt-0 input-field-form rounded w-100"
                placeholder="Message"
                onChange={(event)=>{
                  if(event.target.value!=="")
                  {
                    setValidMessage(/^[A-Za-z0-9/\-.\s]+$/.test(event.target.value));
                  }
                  else
                  {
                    setValidMessage(null);
                  }

                  if(validMessage===true)
                  {
                    setContactUsData({...contactUsData , message : event.target.value});
                  }
                }}
              />
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <button
                disabled={
                  validName===true &&
                  validPhone===true &&
                  validTypeOfService===true &&
                  validMessage===true
                  ? false : true
                }
                onClick={async()=>{
                  try {
                    await fetch("https://dvps.in.net/sendContactEmail.php", {
                      method:"post",
                      body:JSON.stringify(contactUsData)
                    })
                    .then(async(res)=>{
                      res= await res.json();
                      if(res.success)
                      {
                        alert(res.success);
                      }
                      else
                      {
                        alert(res.error);
                      }
                    })
                    .catch(err => alert("Something went wrong!"))
                  } catch (error) {
                      alert("Error while communicating with server, please check your internet connection!");
                  }
                }}
                className="btn-special rounded w-auto">
                    Call Me
                </button>
            </div>
        </div>
      </div>

      

    </div>
  );
}
