import React from "react";
import { useNavigate } from "react-router-dom";

export default function SitemapContent() {
  const navigate = useNavigate();
  const siteMapData = [
    {
      heading: "MAIN LINKS",
      links: [
        {
          name: "Home",
          link: "/",
        },
        {
          name: "About Us",
          link: "/aboutus",
        },
        {
          name: "Enquiry",
          link: "/enquiry",
        },
        {
          name: "Services",
          link: "/services",
        },
        {
          name: "Contact Us",
          link: "/contactus",
        },
      ],
    },
    {
      heading: "PRODUCT LINKS",
      links: [
        {
          name: "Induction Motor",
          link: "/inductionMotor",
        },
        {
          name: "Geared Motor",
          link: "/gearedMotors",
        },
        {
          name: "VFD",
          link: "/pblVfd",
        },
      ],
    },
    {
        heading: "SOCIAL HANDLES",
        links:[
            {
                name:"fa-brands fa-facebook-f fs-medium-css",
                link:"/dvps"
            },
            {
                name:"fa-brands fa-whatsapp fs-medium-css",
                link:"/dvps"
            },
            {
                name:"fa-brands fa-linkedin fs-medium-css",
                link:"/dvps"
            },
            {
                name:"fa-brands fa-twitter fs-medium-css",
                link:"/dvps"
            },
            {
                name:"fa-brands fa-youtube fs-medium-css",
                link:"/dvps"
            },
        ]
    }
  ];
  return (
    <div className="pb-5 col-11 col-sm-11 col-md-11 col-lg-10 col-xl-10 row px-0 display-flex-align-items-top">
      {siteMapData.map((ele, index) => (
        <div
          key={index}
          className="mt-5 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row px-0 display-flex"
        >
          <div className="fw-bold color-theme-dark-blue fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
            {ele.heading}
          </div>

          <div className="mt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            {ele.links.map((link, index) => (
              <div
                key={index}
                className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start"
              >
                <button
                  onClick={() => navigate(link.link)}
                  className="btn-link-css w-auto"
                >
                  {ele.heading!=="SOCIAL HANDLES" ? link.name : <i className={link.name} />}
                </button>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
