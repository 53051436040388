import React, { useEffect } from 'react'
import Header from './Header'
import PrivacyContent from './PrivacyContent'
import Footer from './Footer'

export default function Privacy() {
    useEffect(()=>{
        window.scroll(0,0);
      },[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <Header title="Privacy" />

        <PrivacyContent />

        <Footer />
    </div>
  )
}
