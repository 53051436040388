import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function WhyChooseUs() {
  const navigate = useNavigate();
  const products = [ 
    {
       image:"https://dvps.in.net/dvps_images/geared-motor.jpg",
       title:"Induction Motor",
       company:"LEDL",
       link:"/inductionMotor"
    },
    {
      image:"https://dvps.in.net/dvps_images/pbwr-series.jpg",
      title:"Geared Motor",
      company:"Radicon PowerBuild",
      link:"/gearedMotors"
   },
   {
    image:"https://dvps.in.net/dvps_images/pbl-vfd.jpg",
    image2:"https://dvps.in.net/dvps_images/VFD 2.jpg",
    title:"VFD",
    company:"Radicon PowerBuild And DELTA",
    link:"/pblVfd"
 }
   ];
  return (
    <div className="py-2 mb-0 bg-theme-grey col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="mt-0 fs-large-css fw-bold color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-2 display-flex">
        Our Products
      </div>
      {/* <div className="w-50 display-flex px-0 mt-4 rounded">
        <div className="px-0 m-0 line-theme-blue rounded"></div>
      </div> */}

      <div className="mt-2 col-10 col-sm-10 col-md-12 col-lg-11 col-xl-10 row px-0 display-flex text-center fw-light">
      We are a trusted source for Electromechanical Solutions and have consistently
delivered safe, reliable, intelligent, and economical solutions to various customers. 
We deal in following products to cater various Applications in the industries.
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-10 row px-0 display-flex-align-items-top">

        {products.map((ele,index)=>
          <div key={index} className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row px-4 display-flex-align-items-top">
          <div className="mt-3 service-card py-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            <div className="service-card-front position-relative col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-2 display-flex-align-items-top">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-4 display-flex">
                <span className="service-card-svg overflow-hidden display-flex">
                  {!ele.image2 ? <img
                    src={ele.image}
                    alt={ele.title}
                    className="svg-service w-50 scale-2"
                  />:
                  <>
                  <img
                    src={ele.image}
                    alt={ele.title}
                    className="svg-service w-50"
                  />
                  <img
                    src={ele.image2}
                    alt={ele.title}
                    className="svg-service w-50 "
                  />
                  </>
                }
                </span>
              </div>
              <div className="mt-5 color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex fs-medium-css text-center">
                {ele.title}
              </div>

              <div className="service-card-number col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-end">
                0{index+1}
              </div>
            </div>

            <div className=" service-card-back col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-4 display-flex-align-items-top py-5">
              <div className="mt-0  color-black col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start fs-medium-css ">
                {ele.company}
              </div>
              <div className="w-100 px-0 mt-2 rounded">
                <div className="px-0 m-0 line-black rounded"></div>
              </div>

              <div className="mt-2 fs-slight-small-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                    {/* Working Since 2024  */}
              </div>

              <div className="fs-slight-small-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
                <button onClick={()=>navigate(ele.link)} className="btn-special-black rounded display-flex-center">
                    Read More 
                </button>
              </div>
            </div>
          </div>
        </div>
        )}

        
      </div>

    </div>
  );
}
