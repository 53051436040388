import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function Header(props) {
    const navigate = useNavigate();
  return (
    <div className='position-relative col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
        <div className='height-60vh header-bg-image col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>

        </div>

        {/* <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex height-60vh header-blue-wash'></div> */}

        <div className='header-content col-11 col-sm-11 col-md-11 col-lg-12 col-xl-12 row px-0 display-flex'>
            <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 row px-0 display-flex'>
                <div className='color-theme-dark-blue fs-larger-css fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start'>
                    <h1 className='px-0 display-flex-start fw-bold'>{props.title}</h1>
                </div>

                <div className='mt-3 color-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start'>
                    <button onClick={()=>navigate("/")} className='btn-header fw-normal me-2'>
                        Home
                    </button>
                    <span className="w-auto px-0 me-1 ms-2 color-theme-blue">
                        <i className="fa-solid fa-circle w-auto"></i>
                    </span>
                    <span className='w-auto color-theme-dark-blue ms-2'>
                        {props.title}
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}
