import React from "react";
import { useNavigate } from "react-router-dom";

export default function ServicesContent() {
  const navigate = useNavigate();
  return (
    <div className="py-0 bg-theme-dark-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
        <div className="py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row p-4 display-flex bg-theme-white">
          <div className="text-center col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex fs-large-css fw-bold">
          Need expert engineering repairs or services?
          </div>

          <div className="mt-3 col-12 col-sm-12 col-md-8 col-lg-7 col-xl-7 row px-0 display-flex fw-normal text-center">
          Our team specializes in providing top-notch solutions for all your engineering needs. From 
equipment repairs to maintenance and beyond, we've got you covered. 
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
            
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-4 row display-flex">
              <button  onClick={()=>navigate("/enquiry")} className=" mt-4 btn-special-black-converse w-auto rounded">
                Enquire Now ... !
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="col-12 mt-5 col-sm-12 col-md-12 col-lg-10 col-xl-10 row px-0 display-flex">
        
        <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top-start">
          <div className="mt-4 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-4 color-theme-white display-flex">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              <img
                src="https://dvps.in.net/dvps_images/dvps-logo.jpg"
                alt="DVPS LOGO"
                className="px-0 rounded-circle footer-logo"
              />
            </div>

            <div className="mt-4   col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              <button className="btn-footer-icon">
                {" "}
                <i className="fa-brands fa-facebook-f"></i>{" "}
              </button>
              <button className="btn-footer-icon">
                {" "}
                <i className="fa-brands fa-whatsapp"></i>{" "}
              </button>
              <button className="btn-footer-icon">
                {" "}
                <i className="fa-brands fa-linkedin"></i>{" "}
              </button>
              <button className="btn-footer-icon">
                {" "}
                <i className="fa-brands fa-twitter"></i>{" "}
              </button>
              <button className="btn-footer-icon">
                {" "}
                <i className="fa-brands fa-youtube"></i>{" "}
              </button>
            </div>

          </div>

          <div className="mt-4 col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 row px-4 color-theme-white display-flex-align-items-top">
            <div className="fw-normal fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              Site Navigation
            </div>

            <div className="mt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
              <div className="mt-4 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 px-0 display-flex-column">
                <span className="w-100 display-flex-start">
                  <i className="fa-solid fa-circle color-theme-blue fs-smallest-css me-2"></i>
                  <button onClick={()=>navigate("/")} className="btn-footer fw-normal">Home </button>
                </span>

                <span className="mt-3 w-100 display-flex-start">
                  <i className="fa-solid fa-circle color-theme-blue fs-smallest-css me-2"></i>
                  <button onClick={()=>navigate("/aboutus")}  className="btn-footer fw-normal">About Us </button>
                </span>

                <span className="mt-3 w-100 display-flex-start">
                  <i className="fa-solid fa-circle color-theme-blue fs-smallest-css me-2"></i>
                  <button onClick={()=>navigate("/services")}  className="btn-footer fw-normal">Services </button>
                </span>    
                <span className="mt-3 w-100 display-flex-start">
                  <i className="fa-solid fa-circle color-theme-blue fs-smallest-css me-2"></i>
                  <button onClick={()=>navigate("/applications")}  className="btn-footer fw-normal">Applications </button>
                </span>             
              </div>

              <div className="mt-4 px-0 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 display-flex-column">
              <span className="mt-0 w-100 display-flex-start">
                  <i className="fa-solid fa-circle color-theme-blue fs-smallest-css me-2"></i>
                  <button onClick={()=>navigate("/enquiry")}  className="btn-footer fw-normal">Enquiry </button>
                </span>

              <span className="mt-3 w-100 display-flex-start">
                  <i className="fa-solid fa-circle color-theme-blue fs-smallest-css me-2"></i>
                  <button onClick={()=>navigate("/contactus")}  className="btn-footer fw-normal">Contact</button>
                </span>

                <span className="mt-3 w-100 display-flex-start">
                  <i className="fa-solid fa-circle color-theme-blue fs-smallest-css me-2"></i>
                  <button onClick={()=>navigate("/privacy")}  className="btn-footer fw-normal">Privacy </button>
                </span>
                <span className="mt-3 w-100 display-flex-start">
                  <i className="fa-solid fa-circle color-theme-blue fs-smallest-css me-2"></i>
                  <button onClick={()=>navigate("/sitemap")}  className="btn-footer fw-normal">Site Map </button>
                </span>
              </div>
            </div>

            
          </div>

          <div className="mt-4 mx-0 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row ps-4 color-theme-white  display-flex-end">
          <div className="mt-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-end ">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7568.037377173688!2d73.8229176401343!3d18.48281265587996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2955842afd7d3%3A0x26bbde21c938a40e!2sHingne%20Khurd%2C%20Pune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1721119477166!5m2!1sen!2sin" className="height-40vh px-0"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
      </div>
          </div>

          {/* <div className="mt-4 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row px-4 color-theme-white display-flex-align-items-top">
            <div className="fw-normal fs-medium-css col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
              Latest News
            </div>

            <div className=" mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
              <div className=" col-4 fs-medium-css color-theme-white col-sm-4 col-md-4 col-lg-4 col-xl-4 row pe-3 ps-0 display-flex-end ">
                <img src="https://demo42.atiframe.com/wp-content/uploads/2020/11/Rectangle-993-150x150.jpg" className="w-60 px-0" alt="" />
              </div>
              <div className="color-theme-white col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex-align-items-top">
                <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                Lorem ipsum dolor sit amet.
                </div>
                <div className="mt-2 fw-light col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                August 23, 2017
                </div>
              </div>
            </div>

            <div className="mt-4 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-align-items-top">
              <div className="col-4 fs-medium-css color-theme-white col-sm-4 col-md-4 col-lg-4 col-xl-4 row pe-3 ps-0 display-flex-end ">
                <img src="https://demo42.atiframe.com/wp-content/uploads/2020/11/Rectangle-993-150x150.jpg" className="w-60 px-0" alt="" />
              </div>
              <div className="color-theme-white col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 row px-0 display-flex-align-items-top">
                <div className="fw-bold col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
               Lorem ipsum dolor sit amet consectetur.
                </div>
                <div className="mt-2 fw-light col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start">
                August 23, 2017
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="mt-5 py-4  fw-normal color-theme-white border-top-theme-blue col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-center">
          © 2024 All rights reserved. Designed & Developed at <span className="ms-1 me-0 px-0 w-auto fw-bold color-theme-blue">Growth Studio</span> .
        </div>
      </div>


    </div>
  );
}
